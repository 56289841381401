import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна |Буковиця
			</title>
			<meta name={"description"} content={"Ласкаво просимо в Буковицю, головним місцем для любителів гірськолижних та сноубордів, які бажають підняти свій досвід на снігу."} />
			<meta property={"og:title"} content={"Головна |Буковиця"} />
			<meta property={"og:description"} content={"Ласкаво просимо в Буковицю, головним місцем для любителів гірськолижних та сноубордів, які бажають підняти свій досвід на снігу."} />
			<meta property={"og:image"} content={"https://ternopiltreasures.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://ternopiltreasures.com/img/625453.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ternopiltreasures.com/img/625453.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ternopiltreasures.com/img/625453.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ternopiltreasures.com/img/625453.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ternopiltreasures.com/img/625453.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ternopiltreasures.com/img/625453.png"} />
		</Helmet>
		<Components.Header />
		<Section
			lg-padding="88px 0 88px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://ternopiltreasures.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			quarkly-title="Video-2"
		>
			<Override
				slot="SectionContent"
				flex-wrap="wrap"
				align-items="flex-start"
				justify-content="center"
				flex-direction="column"
			/>
			<Text margin="0px 0px 24px 0px" color="--light" font="normal 400 24px/1.5 --fontFamily-googleSourceSansPro">
				Де кожен поворот - це нова пригода
			</Text>
			<Text
				width="50%"
				lg-width="100%"
				sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				color="--light"
				font="--headline1"
				margin="0px 0px 40px 0px"
			>
				Буковиця
			</Text>
			<Text margin="0px 0px 24px 0px" color="--light" font="normal 400 24px/1.5 --fontFamily-googleSourceSansPro" width="650px">
				Ласкаво просимо в Буковицю, головним місцем для любителів гірськолижних та сноубордів, які бажають підняти свій досвід на снігу.Тут ми поєднуємо хвилювання зимових видів спорту з експертними рекомендаціями, щоб забезпечити не тільки навчитися, але і по -справжньому насолоджуватися кожною миттю.
			</Text>
			<Components.QuarklycommunityKitPopup>
				<Override slot="Wrapper" display="block" width="80%" />
				<Override slot="Button Close" size="40px" transition="transform 0.3s ease-in-out 0s" hover-transform="rotateZ(180deg)" />
				<Override
					slot="Button Open"
					background="url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/VectorPlay.svg?v=2021-09-13T01:01:57.213Z) 0% 0%/contain no-repeat"
					font="normal 400 24px/1.5 --fontFamily-googleRoboto"
					color="--light"
					height="40px"
					opacity="1"
					transition="opacity 0.3s ease-in-out 0s"
					hover-opacity="0.8"
					padding="3px 8px 6px 58px"
				>
					Відтворити відео
				</Override>
				<Components.QuarklycommunityKitYouTube url="https://youtu.be/JCjmmlvVnc8?si=3OleYFvAOwH_wKf8" height="100%" />
			</Components.QuarklycommunityKitPopup>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-4">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				Досвід просто насолоджуйтесь краєм
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="40px 25px"
			>
				<Box min-width="10px" min-height="10px" display="flex">
					<Text
						margin="0px 0 0px 0px"
						display="flex"
						align-items="center"
						justify-content="center"
						font="normal 500 20px/1.2 --fontFamily-sans"
						background="#cbcfd5"
						border-radius="50%"
						width="60px"
						height="60px"
						min-width="60px"
					>
						1
					</Text>
					<Box min-width="10px" min-height="10px" margin="0px 0px 0px 24px">
						<Text margin="0px 0px 15px 0px" font="normal 500 24px/1.2 --fontFamily-sans">
							Експертний тренер
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#474a4d">
							Незалежно від того, чи є ви початківцем, чи хочете вдосконалити передові методи, наші сертифіковані інструктори підходять до вашого підходу до вашого темпу та стилю.
						</Text>
					</Box>
				</Box>
				<Box min-width="10px" min-height="10px" display="flex">
					<Text
						margin="0px 0 0px 0px"
						display="flex"
						align-items="center"
						justify-content="center"
						font="normal 500 20px/1.2 --fontFamily-sans"
						background="#cbcfd5"
						border-radius="50%"
						width="60px"
						height="60px"
						min-width="60px"
					>
						2
					</Text>
					<Box min-width="10px" min-height="10px" margin="0px 0px 0px 24px">
						<Text margin="0px 0px 15px 0px" font="normal 500 24px/1.2 --fontFamily-sans">
							Різноманітна місцевість
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#474a4d">
							Від ніжних схилів для початківців до складних пробіжок для профі, наша різноманітність гарантує, що для кожного є щось.
						</Text>
					</Box>
				</Box>
				<Box min-width="10px" min-height="10px" display="flex">
					<Text
						margin="0px 0 0px 0px"
						display="flex"
						align-items="center"
						justify-content="center"
						font="normal 500 20px/1.2 --fontFamily-sans"
						background="#cbcfd5"
						border-radius="50%"
						width="60px"
						height="60px"
						min-width="60px"
					>
						3
					</Text>
					<Box min-width="10px" min-height="10px" margin="0px 0px 0px 24px">
						<Text margin="0px 0px 15px 0px" font="normal 500 24px/1.2 --fontFamily-sans">
							Сімейний
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#474a4d">
							Ми віримо в радість поділитися схилами.Наші програми обслуговують усі віки, що робить його ідеальним сімейним відпочинком.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="space-between"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						lg-text-align="center"
						text-transform="uppercase"
						margin="0px 0px 8px 0px"
						color="--grey"
						font="--base"
						letter-spacing="2px"
					>
						Навчання стало веселим
					</Text>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Курси лиж та сноуборду - Буковиця
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
					• Прогресивне навчання: Наші уроки структуровані для забезпечення стабільного прогресу з акцентом на розваги та безпеку.
<br />
<br />
• Інтерактивні сесії: взаємодійте з нашими динамічними методами навчання, розробленими для того, щоб ви мотивували та залучали.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://ternopiltreasures.com/img/2.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});